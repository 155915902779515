ul {
    padding: 0;
}

.btnContainer{
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    right: 5%;
    top: 10%;
    max-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  
}
.txtbtnLng{
    color: white;
    text-align: center;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.img{
    
    border-radius: 10px;
}
.conHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: relative;
    margin-bottom: 40px;
}

.itemlist{
background-color: #000;
padding: 5px 7px;
border-radius: 5px;
display: flex;
    align-items: center;
    justify-content: center;
   
}


.list{
    list-style: none;
    gap: 10px;
    display: none;
}

@media screen and ( min-width: 744px ) {
    .list {
        display: flex;
    }




    .conHeader{
        display: flex;
        margin: 0;
        margin-bottom: 55px;
        align-items: center;
        justify-content: space-between;
      
       
        
    }
    
}

.conTextTittle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.titleWelcome{
    color:  #000;
    text-align: center;
    font-family: Noto Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}
.parHeader{
    color: #000;

    text-align: center;
    font-family: Noto Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 300px;
    margin-bottom: 40px;
}

.FaFacebookF{
    width: 50px;
}

.txtTitleHeader{
    margin: auto;
}

@media screen and (min-width: 744px ) {
    .titleWelcome{
        color: var(--text-black, #000);
        text-align: center;
        font-family: Noto Sans;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .txtTitleHeader{
        width: 169px;
        margin: 0;
    }
    .parHeader{
        max-width: 750px;
        color: #000;

        text-align: center;
        font-family: Noto Sans;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 128.571% */

    }
}


@media screen and (min-width: 1200px) {
    .titleWelcome{
        color: #000;
        text-align: center;
        font-family: Noto Sans;
        font-size: 58px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        max-width: 1360px;

    }

    .parHeader{
    color: #000;

    text-align: center;
    font-family: Noto Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 128.571% */
    max-width: 1360px;
    }
    .conHeader{
        display: flex;
        margin: 0;
        margin-bottom: 55px;
        align-items: center;
        justify-content: space-between;
    }
}