.photoContainer{
    position: relative;
      width: 100%;
      height: 100%;
     
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    
.readerContainerPosition{
  position: absolute;
  top: 35px;
  
  z-index: -9999999;
}

@media screen and (min-width: 1360px) {
  .imgGrid{
    width: 160px;
  }
}

.imgGrid{
  width: 100px;
  background-size: cover;
}

.gridChakra{
  display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 40px;
    margin-top: 17px;
    justify-items: center;
    width: 360px;
    align-items: center
}
@media screen and (max-width:743px) {
  .imgGrid:nth-child(1), 
.imgGrid:nth-child(5), 
.imgGrid:nth-child(6), 
.imgGrid:nth-child(7), 
.imgGrid:nth-child(11), 
.imgGrid:nth-child(12), 
.imgGrid:nth-child(13),
.imgGrid:nth-child(17),
.imgGrid:nth-child(18) {
  display: none;
}
}

// @media screen and (min-width: 744px) {

//   .imgGrid:nth-child(n+10){
//   display: none;
// }

// }


@media screen and (min-width: 744px) and (max-width: 1100px) {
   
.photoContainer{
    position: relative;
      width: 100%;
      height: 100%;
     
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
}

.imgGrid:nth-child(1), .imgGrid:nth-child(6), .imgGrid:nth-child(7), .imgGrid:nth-child(12), .imgGrid:nth-child(13), .imgGrid:nth-child(18){
  display: none;
}

.imgGrid{
  width: 140px;
}




.gridChakra{
  display: grid;
    grid-gap: 62px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 17px;
    // margin-top: 60px;
    justify-items: center;
    width: 744px;
}
  
  
  .readerContainerPosition{

    position: absolute;
    top: -30px;

    z-index: -9999999;
  }
}

@media screen and (min-width: 1100px) {
  .readerContainerPosition{
    position: absolute;
    top: 1px;
    // margin-top: 30px;
    z-index: -9999999;

  }

  .gridChakra{
    display: grid;
      grid-gap: 100px;
      grid-template-columns: repeat(6, 1fr);
      
      margin-bottom: 30px;
      
      justify-items: center;
      width: 1360px;
      margin: auto;
  }
  .imgGrid{
    width: 140px;
  }

  .imgGrid:nth-child(7), .imgGrid:nth-child(12) {
    width: 160px;
}
}





    