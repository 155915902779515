.container{
    min-width: 360px;
    margin: auto;
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px
    
}

@media screen and (min-width: 744px) {
    .container {
        max-width: 744px;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (min-width: 1100px) {
    .container{
        max-width: 1440px;
        margin: auto;
       padding-left: 40px;
       padding-right: 40px;
    }
}


