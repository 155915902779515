h1,h2,h3,h4,ul,li,p,div{
    margin: 0;
}


.conFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 0;
    flex-direction: column;
    max-width: 450px;
}

@media screen and (max-width:744px) {
    .conFooter{
        margin: auto;
    }
}


.tittleFooter{
    color: #000;
}


.conFooterTxt{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 170px;
    text-align: center;
}


.txtfooter{
    color: rgba(0, 0, 0, 0.50);

font-family: Noto Sans;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 16px;
width: 300px;
}
.parTxt{
    color: #000;

/* Detail 14 */
font-family: Noto Sans;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
opacity: 0.5;
}

.list{
    list-style: none;
    display: flex;
    gap: 10px;
}
.tittleFooter{
    font-family: Noto Sans;
    font-size: 32px;
    font-style: normal;
    line-height: normal;

}
.itemlist{
   
    padding: 0;
    /* background-color: #000; */
    padding: 0;
    /* border-radius: 10px; */
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: space-around;
    align-items: center;
        
}
.imgitem{
    border-radius: 5px;
}
.spanTxt{
    color: #000;
/* Button */
font-family: Noto Sans;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}



.linkCorp{
    text-decoration: none;
}

@media screen and (min-width:1100px) {
    .txtfooter{
        margin-bottom: 0;
        width: 497px;
    }
}


@media screen and (min-width: 744px) {
    .conFooter{
        display: flex;
        // gap: 10px;
        flex-direction: row;
        max-width: 920px;
    }
    .tittleFooter{
        color: black;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100px;
    }
    .conFooterTxt{
        width: 290px;
    }

}


@media screen and (min-width: 1100px) {
    .conFooter{
        display: flex;
        padding-left: 0;
        padding-right: 0;
        max-width: 1440px;
        justify-content: none;
    }
   
    .tittleFooter{
        font-family: Noto Sans;
        font-size: 32px;
        font-style: normal;

        line-height: normal;
}
    .conFooterTxt{
        width: 494px;
}
    
}