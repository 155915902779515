.containerQuiz {
    margin: auto;
    max-width: 320px;
    border: 1px solid rgba(0, 0, 0, 100%);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    
    
    
}
@media screen and (min-width: 744px) {
    .containerQuiz{
        margin: auto;
        max-width: 1300px;
        border: 1px solid rgba(0, 0, 0, 100%);
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 1200px) {
    .containerQuiz{
        margin-top: 116px;
        margin-bottom: 116px;
        min-width: 1200px;
        padding-top: 30px;
        padding-bottom: 0px;
        max-width: 1200px;
    }
}



