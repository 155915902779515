iframe, video {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
  }



.container{
    display: flex;
    margin: auto;
    align-items: center;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 130px;
    border-radius: 20px;
    border: 1px solid #000;
    padding: 20px 0px 20px 0px;
    background: #FFF;
    width: 320px;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.14);
   
    
}

.btnStart{
    padding: 15px 30px;
    background-color: #FAF9FA;
    outline: none;
    border-radius: 10px;
    border: 1px solid #2A292A;
    width: 160px;

    color: #000;
    text-align: center;
    /* Button */
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
}

.btnStart:hover{
    background-color: #dddadd;
    cursor: pointer;
}
.title{
    color: #000;
    text-align: center;
    font-family: Noto Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
    max-width: 264px;
}

.par{
    color: #000;
    text-align: center;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    max-width: 234px;
   
}

.aspectvideo{
    margin: auto;
   
    max-width: 760px;
}
.conVideo{
    max-width: 760px;
    margin: auto;
}
.videoIframe{
    width: 360px;
}

@media screen and (min-width: 744px) {
    .title{
        color: #000;

    text-align: center;
    font-family: Noto Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    max-width: 399px;
    margin-bottom: 10px;
    }
    .par {
        color: #000;
        text-align: center;
        font-family: Noto Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
        max-width: 283px;
    }

    .btnStart{
        color: #000;

    text-align: center;
    /* Button */
    font-family: Noto Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 160px;
    }
    .container{
        margin-top: 240px;
        padding-top: 20px;
       margin-bottom: 150px;
        min-width: 460px;
        padding-bottom: 20px
    }
    .conVideo{
        margin-bottom: 60px;
    }
    .aspectvideo{
        margin: 0;
       
        background-color: #FAF9FA;
    }

}

@media screen and (min-width: 1100px) {
    .container{
        display: flex;
        margin: auto;
        align-items: center;
        flex-direction: column;
        margin-top: 265px;
        border-radius: 20px;
        border: 1px solid #000;
        padding: 30px 43px 30px 43px;
        background: #FFF;
        min-width: 910px;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.14);
        margin-bottom: 330px;
       
        
    }
    
    .btnStart{
        padding: 15px 35px;
        background-color: #FAF9FA;
        outline: none;
        border-radius: 10px;
        border: 1px solid #2A292A;
       
    
        color: #000;

        text-align: center;
        /* Button */
        font-family: Noto Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        
    }
    
    .btnStart:hover{
        background-color: #dddadd;
        cursor: pointer;
    }
    .title{
        color:  #000;
        text-align: center;
        font-family: Noto Sans;
        font-size: 58px;
        font-style: normal;
        font-weight: 600;
        line-height: 74px; 
        max-width: 830px;
        margin-bottom: 20px;
    
    }
    
    .par{
        color: #000;

    text-align: center;
    /* H5 */
    font-family: Noto Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
    max-width: 783px;
    margin-bottom: 20px;
    }
    .videoIframe{
        max-width: 1360px;
        
    }

    .video{
        
       
        max-width: 1440px;
    }
    .conVideo{
        display: flex;
        max-width: 1100px;
        justify-content: center;
    }
    .aspectvideo{
        max-width: 1440px;
        border-radius: 20px;
      

    }
    
}